
import { ALLOWED_DRONE_TYPES } from "@evercam/shared/constants/ingest"
import exifr from "exifr"
import FileBox from "@/components/ingest/FileBox"
import MapRoute from "@evercam/shared/components/MapRoute"
import * as tus from "tus-js-client/dist/tus.min.js"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { mapStores } from "pinia"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    FileBox,
    MapRoute,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    uploadedUrls: [],
    allowedTypes: ALLOWED_DRONE_TYPES,
    flightMetadata: undefined,
    checkingFlightMetadata: false,
    metadataMissing: false,
    filesWithoutMetadatCount: 0,
    route: [],
    checkedMetadataCount: 0,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore, useProjectStore, useAccountStore),
    areFilesEmpty() {
      return this.ingestFileUploaderStore.files.length === 0
    },
  },
  mounted() {
    this.ingestFileUploaderStore.files = []
    this.checkingFlightMetadata = false
    this.metadataMissing = false
  },
  methods: {
    async onFilesUpdated() {
      this.route = []
      if (this.ingestFileUploaderStore.files.length >= 0) {
        if (this.ingestFileUploaderStore.files.length < 50) {
          this.$notifications.error({
            text: "Select at least 50 images!",
            error: {},
          })
          this.clearForm()

          return
        } else if (this.ingestFileUploaderStore.files.length > 3000) {
          this.$notifications.error({
            text: "Max number of files allowed is 3000!",
            error: {},
          })
          this.clearForm()

          return
        }
        this.filesWithoutMetadatCount = 0
        this.checkedMetadataCount = 0
        this.checkingFlightMetadata = true
        const filesWithMetadata = []
        const promises = this.ingestFileUploaderStore.files.map(
          async (file) => {
            const data = await exifr.parse(file)

            const metadata = await this.checkMetadata(data)
            if (metadata) {
              if (!this.flightMetadata) {
                this.flightMetadata = metadata
              }
              filesWithMetadata.push(file)
            } else {
              this.filesWithoutMetadatCount++
            }
            this.checkedMetadataCount++

            if (data?.longitude && data?.latitude) {
              return {
                location: {
                  lat: data.latitude,
                  lng: data.longitude,
                },
                url: await exifr.thumbnailUrl(file),
              }
            }

            return false
          },
          []
        )
        this.route = (await Promise.all(promises)).filter((v) => v)
        this.ingestFileUploaderStore.files = filesWithMetadata
        if (filesWithMetadata.length < 50) {
          this.$notifications.error({
            text: "Not enough images with metadata found, please select at least 50 drone images with metadata!",
            error: {},
          })
          setTimeout(() => {
            this.checkingFlightMetadata = false
            this.metadataMissing = true
          }, 1000)
        } else {
          setTimeout(() => {
            this.checkingFlightMetadata = false
            this.metadataMissing = false
          }, 1000)
        }
      }
    },
    async processUpload() {
      if (this.areFilesEmpty) {
        this.$notifications.error({
          text: "Please provide files to upload",
          error: {},
        })

        return
      }

      try {
        this.ingestFileUploaderStore.currentUploadStats.isUploading = true
        if (this.flightMetadata) {
          try {
            await IngestApi.drone.prepareForUpload(this.projectId, {
              token: this.token,
              uploadedBy: this.accountStore.email,
            })
          } catch (e) {
            this.$notifications.error({
              text: "Preparing for Upload failed: " + e,
              error: e,
            })

            return
          }
          this.ingestFileUploaderStore.currentUploadStats.percentage = 0
          this.$emit("upload-started")
          for (let i = 0; i < this.ingestFileUploaderStore.files.length; i++) {
            this.uploadedUrls.push(
              await this.uploadFile(this.ingestFileUploaderStore.files[i])
            )
          }
          this.uploadToIngest()
        } else {
          this.$notifications.error({
            text: "No metadata found, check files and try again",
            error: {},
          })
        }
      } catch (error) {
        console.log(error)
        this.$notifications.error({ text: "Couldn't upload files", error })
        this.ingestFileUploaderStore.currentUploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
        }
      }
    },
    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        const tusUploadQuery = new tus.Upload(file, {
          endpoint: `${this.$config.public.portalURL}`,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          parallelUploads: 1,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress: (bytesUploaded) => {
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              ((this.ingestFileUploaderStore.currentUploadStats.uploadedSize +
                bytesUploaded) /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)
          },
          onSuccess: () => {
            this.ingestFileUploaderStore.currentUploadStats.uploadedSize +=
              file.size
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              (this.ingestFileUploaderStore.currentUploadStats.uploadedSize /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)

            file.uploadPercentage = "100"
            const [title, fileExtension] = tusUploadQuery.file.name.split(".")
            resolve({
              url: tusUploadQuery.url,
              title,
              fileExtension,
            })
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            reject(error)
          },
        })
        tusUploadQuery.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            tusUploadQuery.resumeFromPreviousUpload(previousUploads[0])
          }

          // Start the upload
          tusUploadQuery.start()
        })
      })
    },
    uploadToIngest() {
      this.ingestFileUploaderStore.currentUploadStats.isProcessing = true
      this.ingestFileUploaderStore.currentUploadStats.isUploading = false

      IngestApi.drone
        .upload(
          this.projectId,
          {
            token: this.token,
            uploadedBy: this.accountStore.email,
          },
          {
            flightMetadata: this.flightMetadata,
            upload: this.uploadedUrls,
            projectName: this.projectStore.selectedProjectName,
          }
        )
        .then((result) => {
          if (result == 201) {
            this.$root.$emit("upload-completed")
            this.ingestFileUploaderStore.currentUploadStats = {
              percentage: 0,
              totalSize: 0,
              uploadedSize: 0,
              isUploading: false,
              isProcessing: false,
              uploadFinished: true,
            }
            this.uploadedUrls = []
          }
        })
        .catch((error) => {
          this.$notifications.error({ text: "Error uploading images", error })
          this.uploadedUrls = []
          this.ingestFileUploaderStore.currentUploadStats = {
            percentage: 0,
            totalSize: 0,
            uploadedSize: 0,
            isUploading: false,
            isProcessing: false,
            uploadFinished: false,
          }
        })

      return
    },
    clearForm() {
      this.ingestFileUploaderStore.files = []
      this.floor = ""
      this.date = ""
      this.checkingFlightMetadata = false
      this.metadataMissing = false
      this.route = []
    },
    formatGPSCoordinate(coord, coordRef) {
      if (coord.length > 2)
        return coord[0] + "°" + coord[1] + "'" + coord[2] + `" ${coordRef}`

      return ""
    },
    formatEXIFDateTime(dateTime) {
      return this.$moment(dateTime).format("YYYY-MM-DD h:mm:ss")
    },
    async checkMetadata(data) {
      if (
        data &&
        data.DateTimeOriginal &&
        data.GPSLatitude &&
        data.GPSLongitude
      ) {
        data.DateTime = this.formatEXIFDateTime(data.DateTimeOriginal)

        const latitudeFormatted = this.formatGPSCoordinate(
          data.GPSLatitude,
          data.GPSLatitudeRef
        )
        const longitudeFormatted = this.formatGPSCoordinate(
          data.GPSLongitude,
          data.GPSLongitudeRef
        )
        if (latitudeFormatted && longitudeFormatted) {
          return {
            dateTime: data.DateTime,
            gpsAltitude: data.GPSAltitude.toString(),
            gpsCoordinates: latitudeFormatted + "," + longitudeFormatted,
          }
        }
      }
    },
  },
}
